/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Grid, Container } from "@material-ui/core"

import { makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import { Slide } from "react-slideshow-image"
// import { InputGroup, FormControl, Button } from "react-bootstrap"

import Header from "../header"
import Footer from "../footer"
import Search from "../Search"
import "./style/layout.css"
import "react-slideshow-image/dist/styles.css";

const useStyles = makeStyles(theme => ({
  mainContent: {
    marginTop: "95px",
    marginBottom: "45px",
    // [theme.breakpoints.down("md")]: {
    //   marginTop: "210px",
    // },
    [theme.breakpoints.down("sm")]: {
      marginTop: "125px",
    },
  },
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridGap: theme.spacing(3),
  },
  bodyContent: {
    fontFamily: `Kanit`,
    backgroundColor: '#f2f3f5'
  },
  boxAbout: {
    backgroundImage: `url(${process.env.AWS_S3_URL}/assets/iTopPlus1144482049442.jpg)`,
    backgroundSize: "cover",
    // backgroundSize: '100%',
    backgroundRepeat: "no-repeat",
    padding: " 16px 10px",
    minHeight: "255px",
    width: "100%",
  },
  boxLine: {
    backgroundColor: "#c7e9e2",
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
    padding: " 16px 10px",
    minHeight: "255px",
  },
  lineDescription: {
    textAlign: "left",
    "& img": {
      width: "230px",
      height: "auto !important",
      padding: "0px !important",
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  lineQrcode: {
    height: "170px",
  },
  boxContent: {
    textAlign: "center",
    "& img": {
      padding: "10px",
      maxHeight: "170px",
    },
    "& h4": {
      fontSize: "25px",
      fontWeight: "800",
      color: "#444444",
      textTransform: "uppercase",
      marginTop: "10px",
      marginBottom: "14px",
    },
    "& p": {
      fontSize: "17px",
      lineHeight: "28px",
      fontWeight: "400",
    },
    [theme.breakpoints.down("md")]: {
      "& p": {
        fontSize: "14px",
      },
    },
  },
  btnAbout: {
    textAlign: "center",
    backgroundColor: "#fb726a",
    transition: "0.3s",
    cursor: "pointer",
    padding: "8px 10px",
    fontSize: "16px",
    color: "#fff",
  },
  readMore: {
    width: "14%",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    [theme.breakpoints.down("md")]: {
      width: "30%",
    },
  },
  semiFooter: {
    backgroundColor: "#1a1a1a",
    color: "#fff",
    width: "100%",
    paddingBottom: "20px",
    paddingTop: "20px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  imageBest: {
    width: "40%",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    paddingBottom: "20px",
  },
  imageInline: {
    width: "20%",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    paddingBottom: "20px",
  },
  root: {
    flexGrow: 1,
  },
  linkColor: {
    transition: "0.3s",
    color: "#fff",
    fontSize: "17px",
    "&:hover": {
      color: "#e2c366 !important",
      textDecoration: "none",
    },
  },
  boxContactUs: {
    "& span": {
      fontSize: "22px",
      fontWeight: "600",
    },
    margin: "20px auto 40px",
    width: "500px",
    textAlign: "center",
  },
  btnContactUs: {
    backgroundColor: "#e8e8e8",
    color: "#333",
  },
  bestContent: {
    paddingLeft: "150px",
    paddingRight: "150px",
    backgroundColor: "#f4f9fd",
    color: "#444444",
    width: "100%",
    paddingBottom: "20px",
    paddingTop: "20px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "10px",
      paddingRight: "10px",
      "& span": {
        fontSize: "0.675rem",
      },
      "& h3": {
        marginBottom: "0.5rem",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "& span": {
        fontSize: "0.875rem",
      },
      "& h3": {
        marginBottom: "0.5rem",
      },
    },
  },
}))

const slideImages = [
  `${process.env.AWS_S3_URL}/assets/banner-home1.jpg`,
  `${process.env.AWS_S3_URL}/assets/banner-home2.jpg`,
]

const properties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: false,
  arrows: true,
  pauseOnHover: true,
  // onChange: (oldIndex, newIndex) => {
  //   console.log(`slide transition from ${oldIndex} to ${newIndex}`);
  // }
}

const Layout = ({ children, page, showContactUs, exp, onOpenLoginModal }) => {
  const classes = useStyles()
  const statusSearch = true
  // const exp = localStorage.getItem('searchExp');
  const now = new Date().getTime();
  return (
    <div className={classes.bodyContent}>
      <Header siteTitle="best haven khaoyai" onOpenLoginModal={onOpenLoginModal} />
      <Search page={page} />
      {page === "home" && statusSearch && (now > exp) && (
        <div className="slide-container">
          <Slide {...properties}>
            {slideImages.map((image, index) => (
              <div key={index} className="each-slide">
                <img src={image} alt={`Slide ${index}`} style={{ width: "100%" }} />
              </div>
            ))}
          </Slide>
        </div>
      )}

      {page === "home" && statusSearch && (now > exp) && (
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} md={6}>
            <div className={classes.boxAbout}>
              <div className={classes.boxContent}>
                <h4>BEST HAVEN POOL VILLA</h4>
                <p>
                  แหล่งรวมบ้านพักพูลวิลล่าพร้อมสระว่ายน้ำส่วนตัว
                  <br />
                  และบ้านพักตากอากาศที่มากที่สุดและราคาดีที่สุดใน
                  <br />
                  และบริเวณใกล้เคียงโดยสามารถจองผ่านทางเราโดยตรงได้ทันที
                </p>
                <div className={classes.readMore}>
                  <div className={classes.btnAbout}>
                    <a href="/AboutUs" target=" ">
                      <span style={{ color: "#fff" }}>อ่านต่อ</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <div className={classes.boxLine}>
              <div className={classes.boxContent}>
                <h4>ADD FRIENDS</h4>
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={4} md={4}>
                    <div className={classes.lineQrcode}>
                      <img
                        src={`${process.env.AWS_S3_URL}/assets/iTopPlus1291988635987.png`}
                        alt=""
                      ></img>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={8} md={8}>
                    <div className={classes.lineDescription}>
                      <p>
                        สอบถามรายละเอียด และจองบ้านพักผ่านทาง Line Official
                        (สะดวกและเร็วที่สุด)
                      </p>
                      <a
                        href="http://line.me/ti/p/~@besthavenkhaoyai"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <img
                          src={`${process.env.AWS_S3_URL}/assets/iTopPlus41548004893.png`}
                          alt="besthavenkhoyai line official"
                        ></img>
                      </a>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
      <Container maxWidth="lg">
        <main className={classes.mainContent}>{children}</main>
      </Container>
      {page === "home" && (
        <Grid className={classes.root} justifyContent="center" container spacing={2}>
          <div className={classes.bestContent}>
            <Grid item xs={12} sm={12} md={12}>
              <Grid container justifyContent="center" spacing={2}>
                <Grid item sm={12} md={4}>
                  <img
                    className={classes.imageBest}
                    src={`${process.env.AWS_S3_URL}/assets/iTopPlus383766295017.png`}
                    alt=""
                  ></img>
                  <div style={{ textAlign: "center" }}>
                    <h3>{`Best Service & Trust`}</h3>
                    <span>
                      เราเป็นผู้ให้บริการบ้านพักพูลวิลล่าโซนเขาใหญ่ที่ดีที่สุด
                      <br />
                      โดยพร้อมให้บริการด้วยคุณภาพ และความน่าเชื่อถือ
                    </span>
                  </div>
                </Grid>
                <Grid item sm={12} md={4}>
                  <img
                    className={classes.imageBest}
                    src={`${process.env.AWS_S3_URL}/assets/iTopPlus936896280.png`}
                    alt=""
                  ></img>
                  <div style={{ textAlign: "center" }}>
                    <h3>Best Deal In Cheaper Price</h3>
                    <span>
                      เรารวบรวมบ้านพักไว้มากมายพร้อมด้วยโปรโมชั่นดีๆ
                      <br />
                      เพื่อให้ลูกค้าได้ราคาที่ถูกและคุ้มค่าที่สุด
                    </span>
                  </div>
                </Grid>
                <Grid item sm={12} md={4}>
                  <img
                    className={classes.imageBest}
                    src={`${process.env.AWS_S3_URL}/assets/iTopPlus1338557163019.png`}
                    alt=""
                  ></img>
                  <div style={{ textAlign: "center" }}>
                    <h3>Satisfaction Guaranteed</h3>
                    <span>
                      เรารับประกันความพอใจของลูกค้าจากบ้านพักที่คัดสรรมาอย่างดี
                      <br /> และมีมาตรฐาน โดยมีรีวิวจากลูกค้าเก่ามากมาย
                    </span>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      )}
      {showContactUs && (
        <Grid className={classes.root} justifyContent="center" container>
          <div className={classes.semiFooter}>
            {/* <div className={classes.boxContactUs}>
              <span>
                <b>CONTACT US</b>
              </span>
              <InputGroup size="sm" className="mb-3 mt-3">
                <FormControl
                  placeholder="ติดตามข่าวสาร"
                  aria-label="ติดตามข่าวสาร"
                  aria-describedby="basic-addon2"
                />
                <InputGroup.Append>
                  <Button
                    className={classes.btnContactUs}
                    variant="outline-secondary"
                  >
                    ติดตาม
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </div> */}
            <Grid item xs={12} sm={12} md={12}>
              <Grid container justifyContent="center" spacing={2}>
                <Grid item>
                  <img
                    className={classes.imageInline}
                    src={`${process.env.AWS_S3_URL}/assets/iTopPlus168313491633.png`}
                    alt=""
                  ></img>
                  <div style={{ textAlign: "center" }}>
                    <a
                      className={classes.linkColor}
                      href="tel:0809659665"
                      target="_self"
                    >
                      <span>080-695-9665</span>
                    </a>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <a
                      className={classes.linkColor}
                      href="tel:0628982525"
                      target="_self"
                    >
                      <span>062-898-2525</span>
                    </a>
                  </div>
                </Grid>
                <Grid item>
                  <img
                    className={classes.imageInline}
                    src={`${process.env.AWS_S3_URL}/assets/iTopPlus113072111378.png`}
                    alt=""
                  ></img>
                  <div style={{ textAlign: "center" }}>
                    <a
                      className={classes.linkColor}
                      rel="noopener noreferrer"
                      href="mailto:besthavengroup@gmail.com"
                      target="_self"
                    >
                      <span>besthavengroup@gmail.com</span>
                    </a>
                  </div>
                </Grid>
                <Grid item>
                  <img
                    className={classes.imageInline}
                    src={`${process.env.AWS_S3_URL}/assets/iTopPlus432107491327.png`}
                    alt=""
                  ></img>
                  <div style={{ textAlign: "center" }}>
                    <a
                      className={classes.linkColor}
                      rel="noopener noreferrer"
                      href="http://line.me/ti/p/~@besthavenkhaoyai"
                      target="_blank"
                    >
                      <span>@besthavenkhaoyai</span>
                    </a>
                  </div>
                </Grid>
                <Grid item>
                  <img
                    className={classes.imageInline}
                    src={`${process.env.AWS_S3_URL}/assets/iTopPlus1489973983424.png`}
                    alt=""
                  ></img>
                  <div style={{ textAlign: "center" }}>
                    <a
                      className={classes.linkColor}
                      rel="noopener noreferrer"
                      href="https://th-th.facebook.com/besthavenkhaoyai/"
                      target="_blank"
                    >
                      <span>
                        บ้านพักเขาใหญ่ พูลวิลล่าเขาใหญ่
                        <br />
                        Best Haven Khaoyai
                      </span>
                    </a>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      )}
      <Footer />
    </div>
  )
}

Layout.defaultProps = {
  showContactUs: true,
  page: "home",
  exp:new Date().getTime()
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  showContactUs: PropTypes.bool,
  page: PropTypes.string,
  exp: PropTypes.number,
  onOpenLoginModal: PropTypes.func,
}

export default Layout
