import React, { Component } from "react"
import clsx from "clsx"
import { inject, observer } from "mobx-react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import { Grid, Container, IconButton } from "@material-ui/core"
import format from "date-fns/format"
import isValid from "date-fns/isValid"
import startOfDay from "date-fns/startOfDay"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import isSameDay from "date-fns/isSameDay"
import isBefore from "date-fns/isBefore"
import addDays from "date-fns/addDays"
import _ from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarkerAlt, faCalendarAlt, faUserFriends } from "@fortawesome/free-solid-svg-icons"
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers"
import isWithinInterval from "date-fns/isWithinInterval"
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import Button from "@material-ui/core/Button"
import Select from "@material-ui/core/Select"
import Sticky from "../Sticky"
import StickyContent from "../Sticky/StickyContent"
import moment from "moment"
import DateFnsUtils from "@date-io/date-fns"
import "moment/locale/th"
moment.locale("th")


const ColorButton = withStyles({
  root: {
    backgroundColor: "#edc964",
    color: "black",
    paddingTop: "11.5px",
    paddingBottom: "11.5px",
    fontFamily: `Kanit`,
    fontSize: "20px",
    borderRadius: ".5rem",
    "&:hover": {
      backgroundColor: "#ffe69f",
    }
  },
})(Button)

class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      dateRange: {},
      adults: 2,
      adultsKid: 0,
      checkIn: startOfDay(new Date()),
      checkOut: addDays(startOfDay(new Date()), 1),
      location: "",
      expansionPanelOpen: false
    }
  }

  componentDidMount = async () => {
    await this.props.search.fetchLocations()
    const exp = localStorage.getItem('searchExp');
    const now = new Date().getTime();
    if(this.props.page === 'home' && now > exp){
      await this.props.search.searchHouse(this.state.checkIn, this.state.checkOut, this.state.adults, this.state.adultsKid,  this.state.location, this.props.page, false)
    }else{
      if (localStorage.getItem('search')) {
        const search = JSON.parse(localStorage.getItem('search'));
        this.setState({
          location: search.location,
          adults: search.adults,
          adultsKid: search.adultsKid,
          checkIn: new Date(search.checkIn),
          checkOut: new Date(search.checkOut)
        })
      }
    }
  }

  handleChange = event => {
    const name = event.target.name
    this.setState({
      ...this.state,
      [name]: event.target.value,
    })
  }

  handleCalendar = event => {
    this.setState({
      ...this.state,
      open: true,
    })
  }

  onChange = date => this.setState({ date })

  handleStartDateChange = date => {
    this.setState({ checkIn: _.cloneDeep(startOfDay(date)) })
  }
  handleEndDateChange = date => {
    if (isBefore(this.state.checkIn, date)) {
      this.setState({ checkOut: _.cloneDeep(startOfDay(date)) })
    } else {
      return false
    }
  }

  formatSelectLabel = (date, invalidLabel) => {
    let dateClone = _.cloneDeep(date)
    return dateClone && isValid(dateClone)
      ? `${moment(dateClone).format("DD MMM YYYY")}`
      : invalidLabel
  }

  renderWrappedStartDay = (date, selectedDate, dayInCurrentMonth) => {
    const { classes } = this.props
    const selectedDateClone = _.cloneDeep(startOfDay(selectedDate))
    const selectedEndDateClone = _.cloneDeep(startOfDay(this.state.checkOut))
    let dateClone = _.cloneDeep(date)
    let start = selectedDateClone
    let end = selectedEndDateClone
    if (!isBefore(selectedDateClone, end)) {
      end = addDays(startOfDay(selectedDateClone), 1)
      this.setState({ checkOut: end })
    }

    let dayIsBetween = startOfDay(new Date())
    dayIsBetween = isWithinInterval(dateClone, { start, end })

    const isFirstDay = isSameDay(dateClone, start)
    const isLastDay = isSameDay(dateClone, end)

    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
    })
    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
    })
    let labelDay = <span> {format(dateClone, "d")} </span>
    if (isBefore(dateClone, startOfDay(new Date()))) {
      labelDay = (
        <span style={{ color: "rgba(0, 0, 0, 0.38)" }}>
          {" "}
          {format(dateClone, "d")}{" "}
        </span>
      )
    }
    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>{labelDay}</IconButton>
      </div>
    )
  }

  onClickSearch = async () => {
    const adults = this.state.adults
    const adultsKid = this.state.adultsKid
    const checkIn = this.state.checkIn
    const checkOut = this.state.checkOut
    const location = this.state.location
    const page = this.props.page
    await this.props.search.searchHouse(checkIn, checkOut, adults, adultsKid, location, page, true)
    this.setState({ expansionPanelOpen: false });
  }

  renderWrappedEndDay = (date, selectedDate, dayInCurrentMonth) => {
    const mStart = moment(startOfDay(this.state.checkIn))
    const mEnd = moment(startOfDay(selectedDate))
    if (!mStart.isBefore(mEnd)) {
      return
    }
    const { classes } = this.props
    let dateClone = _.cloneDeep(date)
    let selectedDateClone = _.cloneDeep(selectedDate)
    const start = _.cloneDeep(this.state.checkIn)
    const end = selectedDateClone
    let dayIsBetween = startOfDay(new Date())
    if (isValid(start) && isValid(end)) {
      dayIsBetween = isWithinInterval(dateClone, { start, end })
    }
    const isFirstDay = isSameDay(dateClone, start)
    const isLastDay = isSameDay(dateClone, end)
    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
    })
    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
    })
    let labelDay = <span> {format(dateClone, "d")} </span>
    if (isBefore(dateClone, startOfDay(new Date()))) {
      labelDay = (
        <span style={{ color: "rgba(0, 0, 0, 0.38)" }}>
          {" "}
          {format(dateClone, "d")}{" "}
        </span>
      )
    }
    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>{labelDay}</IconButton>
      </div>
    )
  }

  handleSelectLocation = (event, value, reason) => {
    this.setState({ location: value })
  }

  render() {
    const { classes } = this.props
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    const locations = this.props.search.getLocationsJS()
    const { checkIn, checkOut } = this.state

    const adultsList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35]
    return (
      <React.Fragment>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className={classes.mainSearch}>
            <Sticky>
              <StickyContent>
                <div className={classes.searchBar}>
                  <Container>
                    <Accordion expanded={this.state.expansionPanelOpen}>
                      <AccordionSummary
                        style={{ paddingLeft: '10px', paddingRigth: '10px' }}
                        onClick={() => {
                          this.setState({
                            expansionPanelOpen: !this.state.expansionPanelOpen
                          });
                        }}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            <FontAwesomeIcon size='sm' icon={faMapMarkerAlt} /> {this.state.location || 'สถานที่จะไป'}
                          </Grid>
                          <Grid item xs={7} sm={4} md={4} lg={4}>
                            <FontAwesomeIcon size='sm' icon={faCalendarAlt} /> {moment(this.state.checkIn).format("DD MMM")} - {moment(this.state.checkOut).format("DD MMM")}
                          </Grid>
                          <Grid item xs={5} sm={4} md={4} lg={4}>
                            <FontAwesomeIcon size='sm' icon={faUserFriends} /> {+this.state.adults + +this.state.adultsKid} คน
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails style={{ paddingLeft: '10px', paddingRigth: '10px' }}>
                        <Grid justifyContent="center" container spacing={2}>
                          <Grid item xs={12} sm={12} md={6} lg={3}>
                            <Autocomplete
                              freeSolo
                              id="search-box"
                              options={locations}
                              getOptionLabel={option => option.name}
                              style={{ width: "100%" }}
                              onInputChange={this.handleSelectLocation}
                              renderInput={params => (
                                <TextField
                                  fullWidth
                                  {...params}
                                  // InputProps={{ disableUnderline: true }}
                                  className={classes.fieldSearch}
                                  style={{ backgroundColor: "white" }}
                                  label="สถานที่จะไป"
                                  variant="filled"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={3}>
                            <div className={classes.boxCalendarBig}>
                              <div className={classes.checkInBox}>
                                <div className={classes.showDate}>
                                  <DatePicker
                                    InputProps={{ disableUnderline: true }}
                                    label="วันที่เช็คอิน"
                                    value={checkIn}
                                    onChange={this.handleStartDateChange}
                                    renderDay={this.renderWrappedStartDay}
                                    labelFunc={this.formatSelectLabel}
                                    autoOk
                                    disableToolbar
                                    disablePast
                                  />
                                </div>
                              </div>
                              <div className={classes.checkOutBox}>
                                <div className={classes.showDate}>
                                  <DatePicker
                                    InputProps={{ disableUnderline: true }}
                                    label="วันที่เช็คเอาท์"
                                    value={checkOut}
                                    onChange={this.handleEndDateChange}
                                    renderDay={this.renderWrappedEndDay}
                                    labelFunc={this.formatSelectLabel}
                                    autoOk
                                    disableToolbar
                                  />
                                </div>
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={6} sm={6} md={3} lg={2}>
                            <FormControl
                              fullWidth
                              variant="filled"
                              className={classes.formControl}
                            >
                              <InputLabel
                                htmlFor="filled-person-native"
                                className={classes.fontKanit}
                              >
                                ผู้ใหญ่
                          </InputLabel>
                              <Select
                                disableUnderline={true}
                                className={classes.personSelect}
                                native
                                value={this.state.adults}
                                onChange={this.handleChange}
                                inputProps={{
                                  name: "adults",
                                  id: "filled-person-native",
                                }}
                              >
                                <option aria-label="None" value="" />
                                {adultsList.map(val => {
                                  return <option key={val} value={val}>{val}</option>
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={6} sm={6} md={3} lg={2}>
                            <FormControl
                              fullWidth
                              variant="filled"
                              className={classes.formControl}
                            >
                              <InputLabel
                                htmlFor="filled-person-native"
                                className={classes.fontKanit}
                              >
                                เด็ก (ไม่เกิน 11 ปี)
                          </InputLabel>
                              <Select
                                disableUnderline={true}
                                className={classes.personSelect}
                                native
                                value={this.state.adultsKid}
                                onChange={this.handleChange}
                                inputProps={{
                                  name: "adultsKid",
                                  id: "filled-person-native",
                                }}
                              >
                                <option aria-label="None" value="" />
                                {adultsList.map(val => {
                                  return <option key={val} value={val}>{val}</option>
                                })}
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={12} md={6} lg={2}>
                            <ColorButton
                              fullWidth
                              variant="contained"
                              color="primary"
                              onClick={() => this.onClickSearch()}
                            >
                              ค้นหา
                        </ColorButton>
                          </Grid>
                        </Grid>

                      </AccordionDetails>
                    </Accordion>
                  </Container>
                </div>
              </StickyContent>
            </Sticky>
          </div>
        </MuiPickersUtilsProvider>
      </React.Fragment>
    )
  }
}
const styles = theme => ({
  personSelect: {
    borderRadius: '.5rem'
  },
  boxCalendar: {
    "& > div": {
      "& > div": {
        "& > div:nth-child(1)": {
          margin: "0 auto 0 ",
          "& > div:nth-child(3)": {
            [theme.breakpoints.down("xs")]: {
              display: "grid",
            },
          },
        },
        "& > div:nth-child(3)": {
          display: "none",
        },
      },
    },
  },
  fontKanit: {
    fontFamily: `Kanit`,
  },
  mainSearch: {
    position: "relative",
  },
  searchBarHidden: {
    position: "absolute",
    height: "300px",
    width: "100%",
    paddingTop: "5px",
    paddingBottom: "5x",
  },
  searchBar: {
    zIndex: 100,
    position: "absolute",
    color: "dimgrey",
    width: "100%",
    paddingTop: "5px",
    paddingBottom: "5px",
    backgroundColor: "#e2c060",
  },
  fieldSearch: {
    borderRadius: ".5rem",
    fontFamily: `Kanit`,
    "& label": {
      fontFamily: `Kanit`,
    },
  },
  checkInBox: {
    width: "50%",
    padding: "7px",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.09)",
    float: "left",
    borderRadius: ".5rem 0 0 .5rem",
  },
  checkOutBox: {
    width: "50%",
    borderLeft: "1px solid #444",
    padding: "7px",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.09)",
    float: "left",
    borderRadius: "0 .5rem .5rem 0",
  },
  checkIcon: {
    float: "left",
    marginLeft: "10px",
    marginTop: "7px",
  },
  showDate: {
    float: "left",
    marginLeft: "10px",
    marginRight: "10px",
    "& b": {
      color: "#1a1a1a",
    },
    "& label": {
      fontFamily: `Kanit`,
    },
    "& input": {
      fontFamily: `Kanit`,
      paddingBottom: "0px",
    },
  },
  formControl: {
    borderRadius: ".5rem",
    backgroundColor: "white",
    width: "100%",
    minWidth: 170,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  dayWrapper: {
    position: "relative",
  },
  day: {
    width: 40,
    height: 40,
    fontSize: "0.75rem",
    margin: "0 2px",
    color: "inherit",
  },
  customDayHighlight: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: "2px",
    right: "2px",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "50%",
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  highlightNonCurrentMonthDay: {
    color: "#676767",
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  firstHighlight: {
    extend: "highlight",
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  endHighlight: {
    extend: "highlight",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  },
})
Search.propTypes = {
  classes: PropTypes.object.isRequired,
  page: PropTypes.string
}
export const page = inject("search")(observer(Search))
export default withStyles(styles, { withTheme: true })(page)
