import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { Button, TextField, Typography, IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const OtpModal = ({
  isOpen,
  onRequestClose,
  emailOrPhone,
  otp,
  setOtp,
  error,
  isSubmitting,
  handleVerifyOtp,
  countdown,
  handleResendOtp,
  onChangeContact,
  classes,
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    ariaHideApp={false}
    style={{
      overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 200 },
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        border: "none",
        padding: 0,
      },
    }}
  >
    <div className={classes.modalContent}>
      <IconButton className={classes.closeButton} onClick={onRequestClose}>
        <FontAwesomeIcon icon={faTimes} style={{ width: "20px", height: "20px" }} />
      </IconButton>
      <Typography className={classes.title}>
        ยืนยันรหัส <strong>OTP</strong>
      </Typography>
      <Typography className={classes.otpDescription}>
        รหัส OTP ได้ถูกส่งไปยัง {emailOrPhone}
      </Typography>
      <Typography className={classes.otpValidity}>
        รหัส OTP มีอายุการใช้งาน 10 นาที
      </Typography>
      <TextField
        className={classes.input}
        label="รหัส OTP"
        value={otp}
        onChange={(e) => {
          const value = e.target.value.replace(/\D/g, "");
          if (value.length <= 6) setOtp(value);
        }}
        error={!!error}
        helperText={error}
        inputProps={{ maxLength: 6, style: { textAlign: "center" } }}
      />
      <Button
        className={classes.continueButton}
        onClick={handleVerifyOtp}
        disabled={isSubmitting || otp.length !== 6}
      >
        {isSubmitting ? "กำลังตรวจสอบ..." : "ดำเนินการต่อ"}
      </Button>
      <Typography className={classes.resendText}>
        {countdown > 0
          ? `ขอรหัส OTP อีกครั้งได้ใน ${countdown} วินาที`
          : (
            <>
              ยังไม่ได้รับ OTP?{" "}
              <span
                role="button"
                tabIndex={0}
                style={{
                  textDecoration: isSubmitting ? "none" : "underline",
                  cursor: isSubmitting ? "default" : "pointer",
                  color: isSubmitting ? "#888" : "#000",
                }}
                onClick={!isSubmitting ? handleResendOtp : null}
                onKeyDown={(e) => {
                  if (!isSubmitting && (e.key === "Enter" || e.key === " ")) {
                    handleResendOtp();
                  }
                }}
              >
                ส่งอีกครั้ง
              </span>
            </>
          )}
      </Typography>
      <Typography className={classes.changeContact}>
      <span
        role="button"
        tabIndex={0}
        onClick={onChangeContact}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            onChangeContact();
          }
        }}
        style={{ textDecoration: "underline", cursor: "pointer", color: "#3f51b5" }}
      >
        เปลี่ยนอีเมลหรือเบอร์โทร
      </span>
      </Typography>
    </div>
  </Modal>
);

OtpModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  emailOrPhone: PropTypes.string.isRequired,
  otp: PropTypes.string.isRequired,
  setOtp: PropTypes.func.isRequired,
  error: PropTypes.string,
  isSubmitting: PropTypes.bool.isRequired,
  handleVerifyOtp: PropTypes.func.isRequired,
  countdown: PropTypes.number.isRequired,
  handleResendOtp: PropTypes.func.isRequired,
  onChangeContact: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default OtpModal;