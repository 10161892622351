import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { Button, TextField, Typography, IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";

const LoginModal = ({
  isOpen,
  onRequestClose,
  emailOrPhone,
  setEmailOrPhone,
  error,
  isSubmitting,
  handleSubmit,
  classes,
  handleGoogleLogin,
  handleFacebookLogin,
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    ariaHideApp={false}
    style={{
      overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 200 },
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        border: "none",
        padding: 0,
      },
    }}
  >
    <div className={classes.modalContent}>
      <IconButton className={classes.closeButton} onClick={onRequestClose}>
        <FontAwesomeIcon icon={faTimes} style={{ width: "20px", height: "20px" }} />
      </IconButton>
      <Typography className={classes.title}>
        ยินดีต้อนรับสู่ <strong>BEST HAVEN</strong>
      </Typography>
      <TextField
        className={classes.input}
        variant="outlined"
        label="เบอร์โทร / อีเมล"
        fullWidth
        value={emailOrPhone}
        onChange={(e) => setEmailOrPhone(e.target.value)}
        error={!!error}
        helperText={error}
      />
      <Button
        className={classes.continueButton}
        onClick={handleSubmit}
        disabled={isSubmitting}
      >
        {isSubmitting ? "กำลังดำเนินการ..." : "ดำเนินการต่อ"}
      </Button>
      <Typography variant="body1" color="textSecondary" style={{ margin: "10px 0" }}>
        หรือ
      </Typography>
      <Button className={`${classes.socialButton} ${classes.googleButton}`} onClick={handleGoogleLogin}>
        <FontAwesomeIcon icon={faGoogle} style={{ marginRight: "10px" }} />
        <span>ดำเนินการต่อด้วย Google</span>
      </Button>
      <Typography className={classes.termsText}>
        เมื่อเข้าสู่ระบบจะถือว่าท่านยอมรับ{" "}
        <a href="/terms" style={{ color: "#000" }}>ข้อกำหนดและเงื่อนไข</a> /{" "}
        <a href="/privacy" style={{ color: "#000" }}>นโยบายความเป็นส่วนตัว</a>
      </Typography>
    </div>
  </Modal>
);

LoginModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  emailOrPhone: PropTypes.string.isRequired,
  setEmailOrPhone: PropTypes.func.isRequired,
  error: PropTypes.string,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  handleGoogleLogin: PropTypes.func.isRequired,
  handleFacebookLogin: PropTypes.func.isRequired,
};

export default LoginModal;