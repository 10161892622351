import storage from 'store';
import base64 from 'base-64';
import utf8 from 'utf8';
import http from './http'

export const AuthKeys = {
  TOKEN: 'TOKEN',
  USER_INFO: 'USER_INFO',
};

class Auth {
  async sendOTP(emailOrPhone) {
    try {
      const response = await http.post('/customers/login', { email_or_phone: emailOrPhone });
      return response;
    } catch (error) {
      console.error("Error sending OTP:", error);
      throw error;
    }
  }

  async verifyOTP(emailOrPhone, otp, ref, requestToken) {
    try {
      const response = await http.post('/customers/verify', { email_or_phone: emailOrPhone, code: otp, ref, token: requestToken });
      return response;
    } catch (error) {
      console.error("Error verifying OTP:", error);
      throw error;
    }
  }

  async loginGoogle(accessToken) {
    try {
      const response = await http.post('/customers/login-google', { access_token: accessToken });
      return response;
    } catch (error) {
      console.error("Error login google:", error);
      throw error;
    }
  }

  async verifyOTPChangePhone(phone, otp, ref, requestToken) {
    try {
      const response = await http.post('/customers/change-phone', { phone, code: otp, ref, token: requestToken });
      return response;
    } catch (error) {
      console.error("Error verifying OTP:", error);
      throw error;
    }
  }

  setToken(value) {
    storage.set(AuthKeys.TOKEN, value);
  }

  getToken() {
    return storage.get(AuthKeys.TOKEN);
  }

  setUserInfo(value) {
    if(value){
      const bytes = utf8.encode(JSON.stringify(value));
      storage.set(AuthKeys.USER_INFO, base64.encode(bytes));
    }
  }

  getUserInfo() {
    const bytes = base64.decode(utf8.decode(storage.get(AuthKeys.USER_INFO) || ''))
    let data ;
    try {
      if(bytes){
        data = JSON.parse(bytes)
        data.email = utf8.decode(data.email)
        data.phone = utf8.decode(data.phone)
        data.first_name = utf8.decode(data.first_name)
        data.last_name = utf8.decode(data.last_name)
        data.birth_date = utf8.decode(data.birth_date)
      }
    } catch (error) {
      data = null
    }
    return data
  }

  getUserRole() {
    let userInfo = this.getUserInfo();
    let role = 'user';
    if (userInfo) {
      try {
        role = userInfo.role;
      } catch (error) {
        role = 'user'
      }
    }
    return role
  }

  isAuthenticated() {
    const token = this.getToken();
    const userInfo = this.getUserInfo();
    return token && userInfo;
  }

  clear() {
    storage.remove(AuthKeys.TOKEN);
    storage.remove(AuthKeys.USER_INFO);
  }
}

const auth = new Auth();
export default auth;
